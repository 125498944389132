import React from 'react'

const AppFooter = () => {
    const {host} = window.location
    // const host = 'actributario'
    let isAcSRL = host.includes('ecofact')
    let isGipSRL = host.includes('itecperu')
    let isAceSRL = host.includes('contadoresmurrieta')
    let isAtriSRL = host.includes('actributario')

    return (
        <div className="app-footer">
            <div className="app-footer__inner">
                <div className="app-footer-left">
                <span>
                  copyright © and development {new Date().getFullYear()}{' '}
                     <a href="https://alternativascontables.pe" rel="noreferrer" target="_blank">
                            Alternativas Contables S.R.L.
                     </a>
                </span>
                </div>
                <div className="app-footer-right">
                    <span>v<strong>2.01.1010</strong></span>
                </div>
            </div>
        </div>
    )
}

export default AppFooter
